import React from "react";
import { FiArrowRightCircle } from "react-icons/fi";
import TextItem from "../utils/TextItem";

const data = [
  "ব্যাগটি পানি প্রতিরোধক নয়। তাই চাইলেই ব্যাগটি যেকোনো ঋতুতে ব্যবহার করা যাবে না। তাই এটি অনেকের অসুবিধার কারণ হতে পারে।",
  "অতিরিক্ত স্ট্র্যাপ বিরক্তির কারণ হতে পারে মাঝে মাঝে।",
];

const Problems = () => {
  return (
    <div className="effectiveness">
      <div className="container">
        <div className="problems__container">
          <h1 style={{ marginBottom: "10px" }}>অসুবিধা</h1>
          {data.length > 0 &&
            data.map((text, index) => (
              <TextItem
                key={index}
                icon={<FiArrowRightCircle className="icon" />}
                text={text}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Problems;
