import React, { } from "react";

import "./Thanks.css";

import { FiCheckSquare } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function Thanks() {
  const navigate = useNavigate();
  
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
        Thank you for your order
        </title>
      </Helmet>
        <div className="thankyou">
          <div className="thankyou-in">
            <FiCheckSquare className="thank-icon" />
            <h2 className="thank-h2">আপনার অর্ডারটি সফলভাবে সম্পন্ন হয়েছে</h2>
            <p className="thank-p">
              ২৪ ঘণ্টার মধ্যে আমাদের কল সেন্টার থেকে ফোন করে আপনার অর্ডারটি
              কনফার্ম করা হবে ধন্যবাদ!
            </p>
            <button className="thank-button" onClick={() => navigate("/")}>
              Go-Home
            </button>
          </div>
        </div>
   

    </>
  );
}

export default Thanks;
