import React from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import TextItem from "./TextItem";
import StarRating from "./StarRating";
import { PrimaryButton } from "./Button";
import Separator from "./Separator";
import ImageCard from "./ImageCard";

export const DescriptionCard = ({ descriptons }) => {
  return (
    <div className="description__card__container">
      <div className="description__card__header">
        <h1>ইমপোর্টেড ব্যাগ</h1>
        <h2>কোড ১০৮</h2>
      </div>
      <div className="description__card__price__container">
        <div className="description__card__price__section">
          <h3>3,690 TK</h3>
          <h1>3,260 TK</h1>
        </div>
        <div className="description__card__price__text">
          <h4>সারা বাংলাদেশে ডেলিভারি চার্জ সম্পূর্ণ ফ্রী !!</h4>
        </div>
      </div>
      <div className="description__card__body">
        {descriptons.length > 0 &&
          descriptons.map((text, index) => (
            <TextItem
              key={index}
              icon={<FaRegCheckCircle className="icon" />}
              text={text}
            />
          ))}
      </div>
      <div className="description__card__footer">
        <PrimaryButton text={"order now"} handler={() => alert("order now")} />
        <div className="description__card__review">
          <div className="description__card__review__rating">
            <StarRating color={"#ff6700"} rating={4.5} />
            4.5
          </div>
          <div>21 Reviews</div>
          <div>72 Orders</div>
        </div>
      </div>
    </div>
  );
};

export const FeatureCard = ({ features }) => {
  return (
    <div className="feature__card">
      {features.length > 0 &&
        features.map((feature, index) => (
          <div key={index}>
            <p>{feature}</p>
            {features.length - 1 !== index && <Separator />}
          </div>
        ))}
      <div className="feature__btn">
        <PrimaryButton text="Buy Now" handler={() => alert("buy now")} />
      </div>
    </div>
  );
};

export const TextCard = ({ item }) => {
  return (
    <div className="text__card__container">
      <div className="text__card__image">
        <ImageCard src={item.src} />
      </div>
      <div className="text__card__content">
        <h2>{item.title}</h2>
        <p>{item.text}</p>
      </div>
    </div>
  );
};
