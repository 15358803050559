import React from "react";

export const Review = () => {
  return (
    <div className="review">
      <div className="container">
        <div className="review__container">
          <div className="review__title">
            <h2>
              শত শত মানুষের উপকার পাওয়ার রিভিউ আমাদের ফেসবুক পেইজে আছে। তার
              মধ্যে থেকে কিছু রিভিউ এখানে দেয়া হলঃ
            </h2>
            <img
              src="https://naturalsbyrakhi.com/wp-content/uploads/2022/08/Naturals-by-Rakhi-Hair-Oil-Review-1024x455.jpg"
              alt="review__image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
