import React, { useState } from "react";
const Cart = () => {
  const [selectedShipping, setSelectedShipping] = useState("insideDhaka");
  const handleShippingChange = (event) => {
    setSelectedShipping(event.target.value);
  };

  return (
    <div className="cart">
      <div className="cart__container bold__text">
        <div className="cart__container__left">Product</div>
        <div className="cart__container__right">Subtotal</div>
      </div>
      <div className="cart__container">
        <div className="cart__container__left">
          <div className="cart__container__image">
            <img
              src="https://naturalsbyrakhi.com/wp-content/uploads/2022/08/200-ml-300x300.jpeg"
              alt="cart__image"
            />
          </div>
          <div className="cart__container__left__content">
            <div className="cart__product__name">Hair Oil Trial Course</div>
            <div className="cart__product__quantity">X 1</div>
          </div>
        </div>
        <div className="cart__container__right">৳ 500.00</div>
      </div>
      <div className="cart__container no__border__bottom">
        <div className="cart__container__left">Subtotal</div>
        <div className="cart__container__right">৳ 500.00</div>
      </div>
      <div className="cart__container">
        <div className="cart__container__left">Shipping</div>
        <div className="cart__container__right">
          <div className="cart__shipping__methods">
            <label>
              <input
                type="radio"
                value="insideDhaka"
                checked={selectedShipping === "insideDhaka"}
                onChange={handleShippingChange}
              />{" "}
              ঢাকার ভিতরে: ৳ 80.00
            </label>
            <label>
              <input
                type="radio"
                value="outsideDhaka"
                checked={selectedShipping === "outsideDhaka"}
                onChange={handleShippingChange}
              />{" "}
              ঢাকার বাহিরে: ৳ 140.00
            </label>
          </div>
        </div>
      </div>
      <div className="cart__container bold__text no__border__bottom">
        <div className="cart__container__left">Total</div>
        <div className="cart__container__right">৳ 500.00</div>
      </div>
    </div>
  );
};

export default Cart;
