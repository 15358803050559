import React from "react";
import { DescriptionCard } from "../utils/Cards";
import ImageCard from "../utils/ImageCard";
import Slider from "../utils/Slider";

const descriptons = [
  "আকৃতিঃ ৩১ x ১৮ x ২৩ সে.মি।",
  "উপাদানঃ পলিউরেথেন চামড়া এবং পলিয়েস্টার।",
  " রঙঃ লাল।",
  " ভেতরের অংশঃ দুটি মুল অংশ এবং চারটি সিক্রেট পকেট।",
  " বন্ধের উপাদানঃ জিপার।",
  "স্ট্রাপঃ দুটি কাঁধের স্ট্র্যাপ।",
  " ওজনঃ ১.২ কেজি।",
  "বিশেষ বৈশিষ্ট্যঃ পানি প্রতিরোধক।",
];

const images = [
  "https://reviewpart.com/wp-content/uploads/2023/10/ladies-bag-code-108-brown.webp",
  "https://reviewpart.com/wp-content/uploads/2023/10/ladies-bag-code-108-brown.webp",
  "https://reviewpart.com/wp-content/uploads/2023/10/ladies-bag-code-108-white.webp",
  "https://reviewpart.com/wp-content/uploads/2023/10/ladies-bag-code-108-coffee.webp",
  "https://reviewpart.com/wp-content/uploads/2023/10/ladies-bag-code-108-brown.webp",
];

const Description = () => {
  return (
    <div className="description">
      <div className="container">
        <div className="description__container">
          <div className="description__image">
            <ImageCard
              src={
                "https://reviewpart.com/wp-content/uploads/2023/10/ladies-bag-code-108-brown.webp"
              }
            />
            <div className="description__image__slider">
              <Slider>
                {images.length > 0 &&
                  images.map((image, index) => <img key={index} src={image} />)}
              </Slider>
            </div>
          </div>
          <div className="description__text">
            <DescriptionCard descriptons={descriptons} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;
