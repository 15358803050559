import React from "react";
import TextItem from "../utils/TextItem";
import { PrimaryButton } from "../utils/Button";

const data = [
  "অনেকেই হয়ত ব্যাগটির মূল্য নিয়ে শঙ্কিত। কিন্তু ব্যাগটিতে ব্যবহার করা হয়েছে উন্নতমানের উপকরণ। এছাড়াও ব্যাগটির বাহ্যিক সৌন্দর্য যথেষ্ট আকর্ষণীয়। মূলত একটি ব্যাগ নির্বাচনের ক্ষেত্রে ব্যাগটির বৈশিষ্ট্য ও উপযোগিতার প্রতি বেশী নজর দেওয়া প্রয়োজন।",
  "আর এজন্যই লেডিস ব্যাগটি এইসব বিষয়ের কথা মাথায় রেখেই বানানো হয়েছে। এটির ডিজাইন এবং গুনগত মান উন্নত এজন্য এটির গ্রহণযোগ্যতা সকলের কাছেই সমান থাকবে। নানান নামী দামি ব্রান্ডের সাথেও এটি খুব সহজেই তাল মেলাতে পারবে।",
  "ব্যাগটিকে নিত্যদিনের সঙ্গী হিসেবেই পরিচিত। তাই তো এত কিছু দেখে এবং যাচাই করে সকলে বাছাই করেন। ব্যাগটির বৈশিষ্ট্য সম্পর্কে জ্ঞান থাকা খুবই গুরুত্বপূর্ণ। এজন্যই সকল বিষয় বিচার করে তবেই নির্বাচন করা উচিত।",
];
const Decisions = () => {
  return (
    <div className="decisions">
      <div className="container">
        <div className="decisions__container">
          <h1>সিদ্ধান্ত</h1>
          {data.length > 0 &&
            data.map((item, index) => <TextItem key={index} text={item} />)}
          <PrimaryButton text={"buy now"} handler={() => alert("buy now")} />
        </div>
      </div>
    </div>
  );
};

export default Decisions;
