import React from "react";
import Accordion from "../utils/Accordion";

export const Questions = () => {
  return (
    <div className="questions">
      <div className="container">
        <div className="questions__container">
          <h1>সচরাচর জিজ্ঞাসিত প্রশ্ন</h1>
          <Accordion />
        </div>
      </div>
    </div>
  );
};
