import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { motion } from "framer-motion";
import { FiSearch } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { URL } from "../AxiosUrlGlobal";
import "./SearchDesktop.css";

const SearchDesktop = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [clear, setClear] = useState(true);

  const handleSearch = (e) => {
    if (!e.target.value) {
      setClear(false);
    } else {
      setClear(true);
    }
    setSearch(e.target.value);
    if (data.length === 0 && e.target.value) {
      fetchData();
    }
    const filteredResults = data.filter((item) =>
      item.productName.toLowerCase().includes(search.toLowerCase())
    );

    setSearchResult(filteredResults);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearch("");
  };

  const clearSearch = () => {
    setSearch("");
    setSearchResult([]);
    setClear(false);
  };

  function fetchData() {
    axios
      .get(`${URL}/e-search`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  }

  return (
    <div>
      <form className="search-desktop-container" onSubmit={handleSubmit}>
        <div className="search-desktop-input">
          <input
            type="text"
            placeholder="I'am looking for..."
            value={search}
            onChange={handleSearch}
          />
          {search !== "" ? (
            <AiOutlineClose
              className="search-desktop-close-icon"
              onClick={clearSearch}
            />
          ) : (
            <FiSearch className="search-desktop-search-icon" />
          )}
        </div>
      </form>
      {clear && (
        <div
          className={`search-result-container ${
            searchResult.length > 0 ? "margin-bottom-find-result" : ""
          }`}
        >
          {searchResult.length === 0 && search && (
            <div className="search-no-result-found">No Search Result </div>
          )}
          {searchResult.map((data) => (
            <motion.div
              className="Offer-Product"
              key={data._id}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.7 }}
            >
              <img
                src={data.imgeUrl}
                alt={data.productName}
                onClick={() => {
                  if (
                    data._id === "6420774a076c3e2bdd58f2ae" ||
                    data._id === "64207958076c3e2bdd58f2d9" ||
                    data._id === "642b45e176572f46724dfe71" ||
                    data._id === "64207fa2076c3e2bdd58f3e8"
                  ) {
                    navigate(`/product-new/64540211e776cdd487628b64`);
                    return;
                  }
                  if (data.productPage === "2") {
                    navigate(`/product-new/${data._id}`);
                    return;
                  }
                  navigate(`/products/${data._id}`);
                }}
              />

              <div className="sale-out">
                {data.quantity <= 0 ? "Out Of Stock" : "Sale"}
              </div>
              <div className="price-flex">
                <p className="price-line">৳ {data.compare}</p>
                <p className="price">৳ {data.proceSell}</p>
              </div>

              <h2
                onClick={() => {
                  if (data.productPage === "2") {
                    navigate(`/product-new/${data._id}`);
                    return;
                  }
                  navigate(`/products/${data._id}`);
                }}
              >
                {data.productName}
              </h2>
            </motion.div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchDesktop;
