import React, { useState } from "react";
import { Input } from "../utils/Input";
import PackageCard from "../utils/PackageCard";
import { BiSolidLock } from "react-icons/bi";
import Cart from "./Cart";

const cards = [
  {
    id: 1,
    title: "ট্রায়াল কোর্স – ২০০ মিলি তেল",
    description:
      "(ঢাকার ভেতর ৮০ টাকা ঢাকার বাইরে ১৪০ টাকা ডেলিভারি চার্জ যোগ হবে)",
    price: 500.0,
  },
  {
    id: 2,
    title: "হাফ কোর্সঃ ৪০০ মিলি তেল",
    description: "ডেলিভারি চার্জ ফ্রি",
    price: 1000.0,
  },
  {
    id: 3,
    title: "কম্বো প্যাকেজঃ ২৫০ গ্রাম হেয়ার স্পা + ৪০০ মিলি তেল",
    description: "২০০ টাকা ডিসকাউন্ট + ডেলিভারি চার্জ ফ্রি",
    price: 1500.0,
  },
];

export const Form = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    mobile: "",
  });

  const handleCardSelect = (cardId) => {
    setSelectedCard(cardId === selectedCard ? null : cardId);
  };

  function formHandler(event) {
    event.preventDefault();
    console.log(formData);
  }
  return (
    <div className="form">
      <div className="container">
        <div className="form__container">
          <form onSubmit={formHandler}>
            <h2>
              অর্ডার করতে আপনার সঠিক তথ্য দিয়ে নিচের ফর্মটি সম্পূর্ণ পূরন করুন।
              (আগে থেকে কোন টাকা দেয়া লাগবে না। প্রোডাক্ট হাতে পাবার পর টাকা
              দিবেন)
            </h2>
            <div className="form__container__group">
              <div className="form__col">
                <div className="form__row">
                  <p>Billing Details</p>
                  <Input
                    label="আপনার নাম লিখুন"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                    placeholder="আপনার নাম"
                  />
                  <Input
                    label="আপনার ঠিকানা লিখুন "
                    id="address"
                    name="address"
                    value={formData.address}
                    onChange={(e) =>
                      setFormData({ ...formData, address: e.target.value })
                    }
                    placeholder="বাসা নং, রোড নং, থানা, জেলা"
                  />
                  <Input
                    label="আপনার মোবাইল নাম্বারটি লিখুন"
                    id="mobile"
                    name="mobile"
                    value={formData.mobile}
                    onChange={(e) =>
                      setFormData({ ...formData, mobile: e.target.value })
                    }
                    pattern="^\d{11}$"
                    title="Mobile number should be 11 digits long"
                    placeholder="মোবাইল নাম্বার"
                  />
                </div>
                <div className="form__row">
                  <p>কোন প্যাকেজটি নিতে চান সিলেক্ট করুন</p>
                  <div className="form__package__container">
                    {cards.map((card) => (
                      <PackageCard
                        key={card.id}
                        {...card}
                        isSelected={card.id === selectedCard}
                        onSelect={handleCardSelect}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="form__col">
                <div className="form__row">
                  <p>Your Order</p>
                  <Cart />
                </div>
                <div className="form__row">
                  <div className="form__note__content">
                    <div>ক্যাশ অন ডেলিভারি</div>
                    <div className="form__note">
                      পণ্য হাতে পেয়ে ডেলিভারি ম্যানকে পেমেন্ট করতে পারবেন।
                    </div>
                  </div>
                  <div className="form__privacy__text">
                    Your personal data will be used to process your order,
                    support your experience throughout this website, and for
                    other purposes described in our privacy policy.
                  </div>
                  <button type="submit">
                    <BiSolidLock />
                    <div>Place Order ৳1000.00</div>
                  </button>
                </div>
              </div>
            </div>
          </form>
          {/* <h2 className="form__contact__number">
            কোন প্রশ্ন বা সাহায্য লাগলে কল করুনঃ 0172087***
          </h2> */}
        </div>
      </div>
    </div>
  );
};
