import React from "react";
import { TextCard } from "../utils/Cards";

const cardData = [
  {
    id: 1,
    src: "https://reviewpart.com/wp-content/uploads/2023/10/ladies-bag-code-108-brown.webp",
    title: "ডিজাইন:",
    text: "ব্যাগটির লক্ষণীয় বিষয় হল এর কাঁচামাল এবং কোয়ালিটি। এটিতে কাঁচামাল হিসেবে ব্যবহৃত হয়েছে পলিউরেথেন চামড়া এবং পলিয়েস্টার। এই ধরনের উপকরণ একটি ব্যাগের জন্য উপযোগী। আর এজন্যই এটি সকলের কাছে গ্রহণযোগ্যতাও বাড়িয়েছে।",
  },
  {
    id: 2,
    src: "https://reviewpart.com/wp-content/uploads/2023/10/ladies-bag-code-108-white.webp",
    title: "গ্রহণযোগ্য উপযোগিতা:",
    text: "এর পরেই নজরে পড়বে ব্যাগটির রং। ব্যাগটি যদিও কেবল লাল রঙে পাওয়া যাবে তবুও এটি যথেষ্ট আকর্ষণীয় এবং মানানসই। এটি যেকোনো পরিবেশের সাথেই মানিয়ে নিতে সক্ষম।",
  },
  {
    id: 3,
    src: "https://reviewpart.com/wp-content/uploads/2023/10/ladies-bag-code-108-coffee.webp",
    title: "ক্ষয় এবং মরিচা প্রতিরোধক:",
    text: "ব্যাগটি সকল ঋতুতে ব্যবহার করা যাবে। কারণ ব্যাগটি পানি প্রতিরোধক। বৃষ্টির পানিতেও এটি থাকবে শুকনো। যা ব্যাগটির উপযোগিতা অনেকাংশে বাড়িয়ে তোলে।",
  },
];

const EffectivenessCards = () => {
  return (
    <div className="effectiveness__card">
      <div className="container">
        <div className="effectiveness__card__container">
          {cardData.length > 0 &&
            cardData.map((item) => <TextCard key={item.id} item={item} />)}
        </div>
      </div>
    </div>
  );
};

export default EffectivenessCards;
