import React from "react";

const PackageCard = ({
  id,
  title,
  description,
  price,
  isSelected,
  onSelect,
}) => {
  return (
    <div className="package__card__container" onClick={() => onSelect(id)}>
      <div className="package__card__radio">
        <input type="radio" checked={isSelected} readOnly />
      </div>
      <div className="package__card__content">
        <h3>{title}</h3>
        <p>{description}</p>
        <p>৳{price}</p>
      </div>
    </div>
  );
};

export default PackageCard;
