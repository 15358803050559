import React from "react";
import TextItem from "../utils/TextItem";
import { FiArrowRightCircle } from "react-icons/fi";

const data = [
  "ব্যাগটি যথেষ্ট বড় এবং এতে প্রয়োজনীয় সকল কিছু খুব সহজেই ধরে যায়। চাইলেই অন্যান্য জিনিসের সাথে খুব সহজেই ২-৩ টি মোবাইল ফোন নেওয়া সম্ভব। এমনকি একটি ট্যাবলেটও নেওয়া সম্ভব। যেহেতু ব্যাগটিতে চারটি জিপার রয়েছে। এছাড়াও ছোটখাটো সকল কিছুই নিশ্চিন্তে নেওয়া সম্ভব।",
  "ব্যাগটিতে রয়েছে অতিরিক্ত স্ট্র্যাপ ব্যাবহারের সুবিধা। যা অনেক জিনিস নিয়ে বহনে সাহায্য করে। এতে থাকা ম্যাগনেটিক লক সিস্টেমর মাধ্যমে খুব সহজেই লাগিয়ে নেওয়া সম্ভব। এছাড়াও ব্যবহারকারী যদি এটি না লাগাতে চান তাহলে এটি খুলেও রাখতে পারেন।",
  "ব্যাগটির ডিজাইনের জন্য যে কোন বয়সের মানুষ যে কোন জায়গায় ব্যাগটি ব্যবহার করতে পারে। এটি যে কোন জায়গায় মানিয়ে নিতে সক্ষম।",
  "ব্যাগটির ভেতরের পকেটের ডিজাইন বর্তমান যুগের প্রয়োজনীয় জিনিসের কথা মাথায় রেখেই বানানো হয়েছে। যেহেতু ব্যাগটি মহিলারা ব্যবহার করবে তাই তাদের জন্য মেকআপ প্রসাধনী রাখার জন্য আলাদা পকেট রয়েছে।",
];

export const EffectivenessContentList = () => {
  return (
    <div className="effectiveness__content__list">
      <div className="container">
        <div className="effectiveness__content__list__container">
          <div className="effectiveness__content__list__card">
            {data.length > 0 &&
              data.map((item, index) => (
                <TextItem
                  key={index}
                  icon={<FiArrowRightCircle className="icon" />}
                  text={item}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
