import React from "react";

const cardInfo = [
  {
    id: 1,
    title: "ট্রায়াল কোর্স – ২০০ মিলি তেল",
    price: "৫০০ টাকা",
    delivery_charge:
      "(ঢাকার ভেতর ৮০ টাকা ঢাকার বাইরে ১৪০ টাকা ডেলিভারি চার্জ যোগ হবে)",
  },
  {
    id: 2,
    title: "হাফ কোর্সঃ ৪০০ মিলি তেল",
    price: "১০০০ টাকা",
    delivery_charge: "ডেলিভারি চার্জ ফ্রি",
  },
  {
    id: 3,
    title: "কম্বো প্যাকেজঃ ২৫০ গ্রাম হেয়ার স্পা + ৪০০ মিলি তেল",
    price: "১৫০০ টাকা",
    delivery_charge: "ডেলিভারি চার্জ ফ্রি",
  },
];

const Pricing = () => {
  return (
    <div className="pricing">
      <div className="container">
        <div className="pricing__container">
          <h1 className="pricing__container__title">Pricing</h1>
          <div className="pricing__cards__container">
            {cardInfo.length > 0 &&
              cardInfo.map((card) => <PricingCard key={card.id} card={card} />)}
          </div>
        </div>
      </div>
    </div>
  );
};

const PricingCard = ({ card }) => {
  return (
    <div className="pricing__card__container">
      <h3>{card.title}</h3>
      <h1>{card.price}</h1>
      <h3>{card.delivery_charge}</h3>
    </div>
  );
};

export default Pricing;
