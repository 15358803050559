import React from "react";
import AccordionItem from "./AccordionItem";

const Accordion = () => {
  const items = [
    {
      title: "ব্যাগটি কি সকল ঋতুতে ব্যবহার করা যাবে?",
      content:
        "না। ব্যাগটি সকল ঋতুতে ব্যবহার করা যাবে না। যেহেতু ব্যাগটির কাঁচামালে কোন বৃষ্টি প্রতিরোধক উপাদান নেই তাই এটি সতর্কতার সাথে ব্যবহার করতে হবে।",
    },
    {
      title: "ব্যাগটি কি সময় উপযোগী?",
      content:
        "ব্যাগটি নিঃসন্দেহে সময় উপযোগী। যে কোন মানুষ যে কোন প্রয়োজনে ব্যাগটি ব্যবহার করা যাবে। ব্যাগটির নান্দনিকতা ও রুচিশীলতা এক্ষেত্রে ভুমিকা রাখছে। এজন্যই ব্যাগটির সকল ক্ষেত্রেই উপযোগী ও টেকসই।",
    },
    {
      title: "নিত্যদিনের ব্যবহারে ব্যাগটি কি উপযোগী?",
      content:
        "অবশ্যই নিত্যদিনের ব্যবহারে ব্যাগটি শতভাগ উপযোগী। এর মানানসই ও নান্দনিক ডিজাইন যেকোনো পরিবেশের সাথে খাপ খাইয়ে নিতে সক্ষম। এজন্যই ব্যবহারকারী এটিকে তার নিজের প্রয়োজনে নিত্যদিনের সঙ্গী হিসেবে ব্যবহার করতে পারে। এর অনন্য ও আকর্ষণীয় ডিজাইন সৌন্দর্যবর্ধনেও যথেষ্ট ভুমিকা রাখতে পারে।",
    },
  ];

  return (
    <div className="accordion">
      {items.map((item, index) => (
        <AccordionItem key={index} title={item.title} content={item.content} />
      ))}
    </div>
  );
};

export default Accordion;
