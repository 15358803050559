import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useEffect, useContext, useState, useRef } from "react";

import io from "socket.io-client";
import html2canvas from "html2canvas";
import Header from "./Components/Header/Header";
import { Cartcontext } from "../src/context/Context";

import Invest from "./Components/Invest/Invest";
import Products from "./Components/Products/Products";
import Landing from "./Components/Landing/components/Home";
import Landing1 from "./Components/Landing1/components/Home";

import ProductsNew from "./Components/ProductsNew/ProductsNew";
import CheckOut from "./Components/CheckOut/CheckOut";
import Thanks from "./Components/Thanks/Thanks";
import NotFound from "./Components/NotFound/NotFound";
import ViewCategory from "./Components/ViewCategory/ViewCategory";
import Footer from "./Components/Footer/Footer";
import Home from "./Components/Home/Home";

// Bkash
import BkashHome from "./Components/Bkash/Home";
import Success from "./Components/Bkash/Success";
import Fail from "./Components/Bkash/Fail";
import Callback from "./Components/Bkash/Callback";
import Refund from "./Components/Bkash/Refund";

function App() {
  const targetRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const [MouseCoordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const [touchCoordinates, setTouchCoordinates] = useState({ x: 0, y: 0 });
  const [clickCoordinates, setClickCoordinates] = useState({ x: 0, y: 0 });
  const Globalstate = useContext(Cartcontext);
  const state = Globalstate.state;
  const dispatch = Globalstate.dispatch;

  // const socket = io("https://api.rusubd.com/video");
  // const socket = io("http://localhost:4000/video");

  useEffect(() => {
    const currentTime = Date.now();
    const validCartItems = state.filter((item) => item.TimeNow > currentTime);
    if (validCartItems) {
      dispatch({ type: "RESET", payload: [] });
    }
  }, []);

  // useEffect(() => {
  //   if (window.location.protocol !== "https:") {
  //     const httpsUrl = `https://${window.location.hostname}${window.location.pathname}`;
  //     window.location.replace(httpsUrl);
  //   }
  // }, []);

  // const handleTouchMove = (event) => {
  //   const touch = event.touches[0];
  //   if (touch) {
  //     setTouchCoordinates({ x: touch.clientX, y: touch.clientY });
  //   }
  // };

  // useEffect(() => {
  //   const checkIsMobile = () => {
  //     const mobileRegex =
  //       /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i;
  //     setIsMobile(mobileRegex.test(navigator.userAgent));
  //   };
  //   checkIsMobile();
  //   window.addEventListener("resize", checkIsMobile);

  //   return () => {
  //     window.removeEventListener("resize", checkIsMobile);
  //   };
  // }, []);

  // const handleMouseMove = (event) => {
  //   const { clientX, clientY } = event;
  //   setCoordinates({ x: clientX, y: clientY, h: window.location.host });
  // };

  // useEffect(() => {
  //   document.addEventListener("mousemove", handleMouseMove);
  //   return () => {
  //     document.removeEventListener("mousemove", handleMouseMove);
  //   };
  // }, []);

  // const captureScreenshot = async () => {
  //   if (targetRef.current) {
  //     const options = {
  //       useCORS: true,
  //       height: window.innerHeight,
  //       width: window.innerWidth,
  //     };
  //     const canvas = await html2canvas(targetRef.current, options);
  //     const resizedCanvas = document.createElement("canvas");
  //     resizedCanvas.width = canvas.width * 0.5;
  //     resizedCanvas.height = canvas.height * 0.5;
  //     const context = resizedCanvas.getContext("2d");
  //     context.drawImage(
  //       canvas,
  //       0,
  //       0,
  //       resizedCanvas.width,
  //       resizedCanvas.height
  //     );
  //     const imageUrl = resizedCanvas.toDataURL("image/jpeg", 0.5);
  //     const data = {
  //       MouseCoordinates,
  //       clickCoordinates,
  //       touchCoordinates,
  //       isMobile,
  //       height: window.innerHeight,
  //       width: window.innerWidth,
  //       imageUrl,
  //     };
  //     socket.emit("message", data);
  //   }
  // };

  // useEffect(() => {
  //   const interval = setInterval(captureScreenshot, 1000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/index.html" element={<Home />} />
          <Route path="/products/:id/:fid?" element={<Products />} />
          <Route path="/product-new/:id/:fid?" element={<ProductsNew />} />
          <Route path="/view-category/:id" element={<ViewCategory />} />
          <Route path="/checkout" element={<CheckOut />} />
          <Route path="/landing/:id" element={<Landing />} />
          <Route path="/landing1/:id" element={<Landing1 />} />
          <Route path="*" element={<NotFound />} />
          <Route path="Invest" element={<Invest />} />
          <Route path="/bhome" element={<BkashHome />} />
          <Route path="/callback" element={<Callback />} />
          <Route path="/thanks" element={<Thanks />} />
          <Route path="/success" element={<Success />} />
          <Route path="/fail" element={<Fail />} />
          <Route path="/refund" element={<Refund />} />
        </Routes>

        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
