import React from "react";
import Header from "./Header";
import Description from "./Description";
import Features from "./Features";
import Effectiveness from "./Effectiveness";
import EffectivenessCards from "./EffectivenessCards";
import { EffectivenessContentList } from "./EffectivenessContentList";
import Problems from "./Problems";
import Decisions from "./Decisions";
import { Footer } from "./Footer";
import { Questions } from "./Questions";

import "../landing-1.css";

const Home = () => {
  return (
    <>
      {/* <Header /> */}
      <Description />
      <Features />
      <Effectiveness />
      <EffectivenessCards />
      <EffectivenessContentList />
      <Problems />
      <Questions />
      <Decisions />
    </>
  );
};

export default Home;
