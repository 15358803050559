import React, { useEffect, useState, useContext } from "react";
import { Cartcontext } from "../../context/Context";
import { useParams, useNavigate } from "react-router-dom";
import "./Products.css";
import { FaPhoneSquareAlt } from "react-icons/fa";
import RelatedProduct from "../RelatedProduct/RelatedProduct";
import axios from "axios";
import { BsMessenger } from "react-icons/bs";
import { URL } from "../AxiosUrlGlobal";
import myAxios from "../AxiosHeader";
import { useBeforeunload } from "react-beforeunload";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactLoading from "react-loading";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
function Products() {
  const [bopen, setOpen] = useState(true);
  let { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState([]);
  const [productLoad, setProductLoad] = useState(true);
  const [price, setPrice] = useState("");
  const [image, setimage] = useState("");
  const [code, setCode] = useState("");
  const [colorn, setColorn] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [Available, setAvailable] = useState();
  const [checkoutData, setCheckoutData] = useState();
  const [sizeData, setSizeData] = useState();
  const [SelectSize, setSelectSize] = useState();
  const [SizeGid, setSizeGid] = useState();
  const [SizeQuantity, setSizeQuantity] = useState();
  const [redBorder, setRedBorder] = useState(false);
  const [redBorderSize, setRedBorderSize] = useState(false);
  const Globalstate = useContext(Cartcontext);
  const state = Globalstate.state;
  const dispatch = Globalstate.dispatch;
  const [Number, setNumber] = useState();
  const [WhNumber, setWhNumber] = useState();
  const [MessangerUrl, setMessangerUrl] = useState();
  const [DeliveryDhaka, setDeliveryDhaka] = useState();
  const [DeliveryOut, setDeliveryOut] = useState();

  useEffect(() => {
    myAxios
      .get(`${URL}/e-store`)
      .then((res) => {
        console.log(res.data);
        setNumber(res.data.number);
        setWhNumber(res.data.WhatsApp);
        setDeliveryDhaka(res.data.Dhaka);
        setDeliveryOut(res.data.Outside);
        setMessangerUrl(res.data.MessangerUrl);
      })
      .catch((err) => console.log(err.response.data));
  }, []);

  useEffect(() => {
    if (
      id === "6420774a076c3e2bdd58f2ae" ||
      id === "64207958076c3e2bdd58f2d9" ||
      id === "642b45e176572f46724dfe71" ||
      id === "64207fa2076c3e2bdd58f3e8"
    ) {
      navigate(`/products/64540211e776cdd487628b64`);
    }
  }, []);

  const stock_color_size = SizeQuantity ? SizeQuantity : 1;
  const stock_color = checkoutData?.quantity;
  const stock = product?.quantity;
  console.log(stock_color, quantity);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  const pInc = () => {
    if (product.variation === "no") {
      if (quantity >= stock) {
        toast.error("Quantity Limit");
        setQuantity(1);
      } else {
        setQuantity(parseInt(quantity + 1));
      }
    }
    if (product.variation === "color") {
      if (quantity >= stock_color) {
        toast.error("Quantity Limit");
        setQuantity(1);
      } else {
        setQuantity(parseInt(quantity + 1));
      }
    }
    if (product.variation === "colorSize") {
      if (quantity >= stock_color_size) {
        toast.error("Quantity Limit");
        setQuantity(1);
      } else {
        setQuantity(parseInt(quantity + 1));
      }
    }
  };

  const pDic = () => {
    if (quantity <= 1) {
      return;
    }
    setQuantity(parseInt(quantity - 1));
  };

  const checkout = () => {
    if (stock_color_size <= 0 || stock_color <= 0 || stock <= 0) {
      return toast.error("Out Of Stock");
    }
    if (product.variation === "colorSize" || product.variation === "color") {
      if (!checkoutData) {
        return setRedBorder(true);
      }
    }
    if (product.variation === "colorSize") {
      if (!SelectSize) {
        return setRedBorderSize(true);
      }
    }

    if (product.variation === "colorSize") {
      toast.success(
        `${checkoutData.colorName} কালার ${SelectSize} সাইজ কার্ট এ অ্যাড করাহয়েছে`
      );
      const modifycolorSize = {
        id: product.id,
        title: product.productName,
        colorName: checkoutData.colorName,
        Size: SelectSize,
        image: checkoutData.colorImage,
        price,
        quantity,
        VariationType: product.variation,
        gid: SizeGid,
        pCode: product.pCode,
        TimeNow: Date.now(),
        Available: Available,
      };
      dispatch({ type: "ADD", payload: modifycolorSize });
    }

    if (product.variation === "color") {
      toast.success("কার্ট এ অ্যাড করা হয়েছে অর্ডার করুন");
      let modifycolor = Object.assign(checkoutData, {
        quantity: quantity,
        VariationType: product.variation,
        title: product.productName,
        id: product.id,
        pCode: product.pCode,
        TimeNow: Date.now(),
      });
      setCheckoutData(modifycolor);
      dispatch({ type: "ADD", payload: checkoutData });
    }

    if (product.variation === "no") {
      const modifyNo = {
        id: product.id,
        title: product.productName,
        colorName: product.color,
        image: product.imgeUrl,
        price: product.proceSell,
        quantity,
        VariationType: product.variation,
        gid: product.gid,
        pCode: product.pCode,
        TimeNow: Date.now(),
        Available: Available,
      };
      dispatch({ type: "ADD", payload: modifyNo });
    }

    // state.map((data) => {
    //   if (
    //     data.colorName === product.color ||
    //     data.colorName === checkoutData.colorName
    //   ) {
    //     toast.error("Already added to cart");
    //   }
    // });
  };
  const orderNowButton = () => {
    if (stock_color_size <= 0 || stock_color <= 0 || stock <= 0) {
      return toast.error("Out Of Stock");
    }
    if (product.variation === "colorSize" || product.variation === "color") {
      if (!checkoutData) {
        return setRedBorder(true);
      }
    }
    if (product.variation === "colorSize") {
      if (!SelectSize) {
        return setRedBorderSize(true);
      }
    }
    if (product.variation === "colorSize") {
      const modifycolorSize = {
        id: product.id,
        title: product.productName,
        colorName: checkoutData.colorName,
        Size: SelectSize,
        image: checkoutData.colorImage,
        price,
        quantity,
        VariationType: product.variation,
        pCode: product.pCode,
        gid: SizeGid,
        TimeNow: Date.now(),
        Available: Available,
      };
      dispatch({ type: "ADD", payload: modifycolorSize });
    }

    if (product.variation === "color") {
      let modifycolor = Object.assign(checkoutData, {
        quantity: quantity,
        VariationType: product.variation,
        title: product.productName,
        id: product.id,
        pCode: product.pCode,
        TimeNow: Date.now(),
      });
      setCheckoutData(modifycolor);
      dispatch({ type: "ADD", payload: checkoutData });
    }

    if (product.variation === "no") {
      const modifyNo = {
        id: product.id,
        title: product.productName,
        colorName: product.color,
        image: product.imgeUrl,
        price: product.proceSell,
        quantity,
        VariationType: product.variation,
        gid: product.gid,
        pCode: product.pCode,
        TimeNow: Date.now(),
        Available: Available,
      };
      dispatch({ type: "ADD", payload: modifyNo });
    }

    state.map((data) => {
      if (
        data.colorName === product.color ||
        data.colorName === checkoutData.colorName
      ) {
        toast.success("Already added to cart");
      }
    });
    navigate("/checkout");
  };
  useEffect(() => {
    axios
      .get(`${URL}/e-product/${id}`)
      .then((res) => {
        setProduct(res.data);
        setProductLoad(false);
        setOpen(false);
        if (res.data === "No product found..") {
          navigate("/");
        }
      })
      .catch((err) => console.log(err));
  }, [id]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{product.productName}</title>
      </Helmet>
      {productLoad && (
        <div
          style={{
            width: "100vw",
            height: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "20px",
            transition: "1s",
          }}
        >
          <ReactLoading
            type="spin"
            color="rgb(226,115,29)"
            height={60}
            width={60}
          />
        </div>
      )}

      {product && (
        <motion.div
          className="main-product"
          initial={{ opacity: 0, scale: 0.6 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
        >
          <ToastContainer />
          <div className="image-and-buynow">
            <div className="product-photo">
              <img src={image ? image : product.imgeUrl} alt="productimage" />

              {/* Start product code and stock show on product photo */}
              <div className="code-and-stock-on-image">
                <div className="Product-code-image">
                  <p>কোড: {code ? code : product.pCode}</p>
                </div>
                <div className="Product-price-on-image">
                  <p className="product-price-line-through-on-image">
                    ৳ {product.compare}
                  </p>
                  <p className="product-price-on-image">
                    ৳ {price ? price : product.proceSell}
                  </p>
                </div>
              </div>
              {/* Start product code and stock show on product photo */}
            </div>

            <div className="product-buy-now">
              <h2>{product.productName?.substring(0, 45) + "..."}</h2>
              <div className="detail-price">
                <p className="product-price-line-through">
                  ৳ {product.compare}
                </p>
                <p className="product-price-">
                  ৳{price ? price : product.proceSell}
                </p>
              </div>

              <p className="product-code">
                প্রোডাক্ট কোড: {code ? code : product.pCode}
              </p>

              {product.variation === "color" && (
                <div className="color-variation">
                  <p>Color: {colorn ? colorn : null}</p>
                  {redBorder && (
                    <div
                      style={{
                        color: "red",
                        border: "2px solid red",
                        padding: "10px",
                      }}
                    >
                      নিচে যেকোনো একটা ছবিতে ক্লিক করে কালার সিলেক্ট করুন
                    </div>
                  )}
                  <div className="color-image">
                    {product &&
                      product.VariationData?.map((data, index) => {
                        console.log(data);
                        return (
                          <img
                            key={index}
                            src={data.image}
                            alt={data.colorName}
                            onClick={() => {
                              setimage(data.image);
                              setPrice(data.price);
                              setCode(data.colorName);
                              setColorn(data.colorName);
                              setCheckoutData(data);
                              setQuantity(1);
                              setRedBorder(false);
                              toast.success(
                                `${data.colorName} সিলেক্ট হয়েছে । অর্ডার করুন`
                              );
                            }}
                          />
                        );
                      })}
                  </div>
                </div>
              )}
              {product.variation === "colorSize" && (
                <div className="color-variation">
                  <p>Color: {colorn ? colorn : null}</p>
                  {redBorder && (
                    <div
                      style={{
                        color: "#b12727",
                        border: "2px solid #b12727",
                        padding: "6px",
                        fontSize: "13",
                        textAlign: "center",
                      }}
                    >
                      নিচে যেকোনো একটা ছবিতে ক্লিক করে কালার সিলেক্ট করুন
                    </div>
                  )}
                  {redBorderSize && (
                    <div
                      style={{
                        color: "#b12727",
                        border: "2px solid #b12727",
                        padding: "6px",
                        fontSize: "13",
                        textAlign: "center",
                      }}
                    >
                      যেকোনো একটা সাইজ এ ক্লিক করে অর্ডার করুন
                    </div>
                  )}
                  {product &&
                    product.VariationData?.map((data, index) => {
                      return (
                        <>
                          <div className="color-image">
                            <img
                              key={index}
                              src={data.colorImage}
                              alt={data.colorName}
                              onClick={() => {
                                setimage(data.colorImage);
                                setPrice(data.price);
                                setCode(data.colorName);
                                setColorn(data.colorName);
                                setCheckoutData(data);
                                setSizeData(data.sizes);
                                setQuantity(1);
                                setRedBorder(false);
                                setSelectSize("");
                                toast.success(
                                  `${data.colorName} সিলেক্ট হয়েছে । সাইজ সিলেক্ট করুন`
                                );
                              }}
                            />
                            {data.sizes.map((data) => {
                              return (
                                <div className="choose-size-main">
                                  <div
                                    className="choose-size"
                                    key={index}
                                    onClick={() => {
                                      setPrice(data.price);
                                      setSelectSize(data.sizeName);
                                      setSizeGid(data.gid);
                                      setSizeQuantity(data.quantity);
                                      setRedBorderSize(false);
                                      setQuantity(1);
                                      toast.success(
                                        `${data.sizeName} সাইজ সিলেক্ট হয়েছে | অর্ডার করুন`
                                      );
                                    }}
                                  >
                                    {data.sizeName}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      );
                    })}
                </div>
              )}

              {/* {product.variation === "colorSize" && (
                <div className="variation-size">
                  {sizeData &&
                    sizeData.map((data, index) => {
                      return (
                        <div className="choose-size-main">
                          <div
                            className="choose-size"
                            key={index}
                            onClick={() => {
                              setPrice(data.price);
                              setSelectSize(data.sizeName);
                              setSizeGid(data.gid);
                              setSizeQuantity(data.quantity);
                              setRedBorderSize(false);
                              setQuantity(1);
                              toast.success(
                                `${data.sizeName} সাইজ সিলেক্ট হয়েছে | অর্ডার করুন`
                              );
                            }}
                          >
                            {data.sizeName}
                          </div>
                        </div>
                      );
                    })}
                </div>
              )} */}
              <div className="Quantity-plus-">
                <p>পরিমান</p>
                <button onClick={pDic}>-</button>
                <input
                  type="text"
                  className="Quantity-Input"
                  name="Quantity"
                  value={quantity}
                  onChange={(e) => {
                    if (typeof e.target.value != "number") {
                      return;
                    } else {
                      setQuantity(e.target.value);
                    }
                  }}
                />
                <button onClick={pInc}>+</button>
              </div>
              <button className="order-button-product" onClick={orderNowButton}>
                অর্ডার করুন
              </button>
              <button className="cart-button-product" onClick={checkout}>
                কার্ট-এ যোগ করুন
              </button>
              <h3 className="order-by-phone">ফোনে অর্ডারের জন্য ডায়াল করুন</h3>

              <div className="call-product">
                <FaPhoneSquareAlt className="product-d-icon" />
                <a
                  style={{ color: "#fff", textDecoration: "none" }}
                  href={`tel:+88${Number}`}
                >
                  {Number}
                </a>
              </div>
              <div className="call-product">
                <WhatsAppIcon className="product-d-icon" />
                <a
                  style={{ color: "#fff", textDecoration: "none" }}
                  href={`https://wa.me/+88${WhNumber}`}
                  target="_blabk"
                >
                  WhatsApp
                </a>
              </div>

              <div className="pro-facebtn ">
                <BsMessenger className="product-d-icon" />
                <a
                  style={{ color: "#fff", textDecoration: "none" }}
                  href={MessangerUrl}
                  target="_blabk"
                >
                  Messanger
                </a>
              </div>
              <div className="product-delivery">
                <p>ঢাকার ভিতরে ডেলিভারি</p>
                <p>৳ {DeliveryDhaka}</p>
              </div>
              <div className="product-delivery">
                <p>ঢাকার বাইরে ডেলিভারি</p>
                <p>৳ {DeliveryOut}</p>
              </div>
            </div>
          </div>
          <div className="product-description">
            <div className="des-faq">
              <div className="des">
                <h2>Description</h2>
                <div
                  className="Product-Desc-in"
                  dangerouslySetInnerHTML={{ __html: product.productDec }}
                />
              </div>
              {/* <div className="faq">
                <h2>F.A.Q</h2>
                <p>
                  আপনার কোন প্রশ্ন জানার থাকলে নিচের " আমার প্রশ্ন আছে " বাটন
                  টিতে ক্লিক করে আপনার মোবাইল নাম্বার আর আপনার প্রশ্নটি লিখে
                  সাবমিট করুন আমরা আপনাকে কল করে জানাবো
                </p>
                <button className="faq-button">আমার প্রশ্ন আছে</button>
              </div> */}
            </div>
          </div>
          <RelatedProduct />
        </motion.div>
      )}
    </>
  );
}

export default Products;
