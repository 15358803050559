import React from "react";
import Accordion from "../utils/Accordion";

const Faq = () => {
  return (
    <div className="landing1faq">
      <div className="container">
        <div className="landing1faq__container">
          <div className="landing1faq__title">
            <h2>
              নীচের প্রশ্নগুলো আমরা প্রতিনিয়ত পেয়ে থাকি। আশা করি এর মধ্যে আপনি
              আপনার প্রশ্নের উত্তর পেয়ে যাবেন এবং আমাদের তেলের সম্পর্কে
              বিস্তারিত জানতে পারবেন...
            </h2>
          </div>
          <Accordion />
        </div>
      </div>
    </div>
  );
};

export default Faq;
