import React from "react";
import { Header } from "./Header";
import Video from "./Video";
import Faq from "./Faq";
import Pricing from "./Pricing";
import { Review } from "./Review";
import Footer from "./Footer";
import { Form } from "./Form";

import "../landing-2.css";

const Home = () => {
  return (
    <>
      {/* <Header /> */}
      <Video />
      <Faq />
      <Pricing />
      <Review />
      <Form />
    </>
  );
};

export default Home;
