import React from "react";
import VideoPlayer from "../utils/VideoPlayer";

const Video = () => {
  return (
    <div className="container">
      <div className="video__container">
        <h2>চুল সমস্যার প্রাকৃতিক সমাধান</h2>
        <VideoPlayer />
        <h2>
          চুলের যেকোনো সমস্যার যদি প্রাকৃতিক ভাবে সমাধান করতে চান কোন
          পার্শ্ব-প্রতিক্রিয়া ছাড়া তাহলে এখনি অর্ডার করুন প্রাকৃতিক প্রায় ৩০ টি
          উপাদানে তৈরি Natural Hair oil
        </h2>
        <button>এখানে অর্ডার করুন</button>
      </div>
    </div>
  );
};

export default Video;
