import React from "react";

const Effectiveness = () => {
  return (
    <div className="effectiveness">
      <div className="container">
        <div className="effectiveness__container">
          <h1 style={{ marginBottom: "10px" }}>সুবিধা</h1>
          <p>
            ঊপরের ব্যাগটির বৈশিষ্ট্য দেখেই আন্দাজ করা যায় ব্যাগটি অন্যান্য
            ব্যাগের তুলনায় অনেক আলাদা। তাই এর এই আলাদা হওয়ায় পেছনে যে সুবিধা
            রয়েছে তা নিয়ে আলোচনা করাই বাহুল্য। নিচে ব্যাগটির কিছু উল্লেখযোগ্য
            সুবিধা নিয়ে আলোচনা করা হলঃ
          </p>
        </div>
      </div>
    </div>
  );
};

export default Effectiveness;
